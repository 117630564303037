
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    

.loading {
  position: relative;
  animation: rotate 2s linear infinite;

  .circle {
    width: 100%;
    height: 100%;
    animation: animate 6s linear infinite;
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 30;
    stroke-dashoffset: 30;
  }
}

.rounded {
  border-radius: 9999px;
}


@keyframes animate {
  0%,
  100% {
    stroke-dashoffset: 150;
  }

  50% {
    stroke-dashoffset: 0;
  }

  50.1% {
    stroke-dashoffset: 300;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
