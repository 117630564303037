// Font
$font-base:
  pretendard,
  -apple-system,
  blinkmacsystemfont,
  system-ui,
  roboto,
  'Helvetica Neue',
  'Segoe UI',
  'Apple SD Gothic Neo',
  'Noto Sans KR',
  'Malgun Gothic',
  sans-serif;
$font-weights: (
  'r': 400,
  'm': 500,
  'sb': 600,
  'b': 700,
  'eb': 800,
);

// Point
$breakpoint-mobile-sm: 420px;
$breakpoint-mobile: 560px;
$breakpoint-tablet: 1024px;
$breakpoint-laptop-sm: 1280px;
$breakpoint-laptop: 1440px;

// 웹접근성: 키보드 접근 포커스 스타일
$focus-ring:
  0 0 0 1px rgba(#6758ff, 20%),
  0 0 0 4px rgba(#6758ff, 8%);

// TODO: $secondary 키와 같습니다. 다른 파일에서 이 변수를 사용하므로, 추후 리팩토링 필요
$primary-background: #f3f2ff;
$primary-background-hover: #e9e8ff;

// Responsive
$container-max-width: 1140px;
$laptop: 1200px;
$tablet: 1024px;
$mobile: 560px; // tailwind: 768px
$mobile-sm: 420px;
