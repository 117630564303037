
// responsive
@mixin laptop {
  @media (max-width: $laptop) {
    @content;
  }

  @media (max-width: $breakpoint-laptop) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }

  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }

  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin mobile-sm {
  @media (max-width: $mobile-sm) {
    @content;
  }

  @media (max-width: $breakpoint-mobile-sm) {
    @content;
  }
}


@mixin transition($property, $duration, $timing-function, $delay) {
  transition: $property $duration $timing-function $delay;
}

@mixin space-x($value) {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-left: $value;
  }
}

@mixin space-y($value) {
  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: $value;
  }
}


@mixin laptop-sm {
  @media (max-width: $breakpoint-laptop-sm) {
    @content;
  }
}

@mixin focus-ring {
  &:focus-visible {
    outline: none;
    box-shadow: $focus-ring;
  }
}

